import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import { eventQBus } from "@otto-ec/global-resources/event-q-bus";
import tracker from "../tracking/Tracker";

const FILTERLIST_DESKTOP_EXPANDER_ID = "heureka_filterlistExpanderDesktop";
const FILTERLIST_EXPANDER_SELECTOR = "heureka_filterlistExpander";
const FILTERLIST_EXPANDER_EXPANDED_CLASS = `${FILTERLIST_EXPANDER_SELECTOR}--expanded`;

export class FilterlistExpander {
  static readonly factory = HeurekaElementFactory.byClass(FILTERLIST_EXPANDER_SELECTOR, FilterlistExpander);

  constructor(readonly elem: HTMLElement) {}

  /*               */
  static register() {
    eventQBus.on("heureka.filters.loaded", FilterlistExpander.initAll);
  }

  static initAll() {
    FilterlistExpander.factory.forEach((expander) => {
      expander.init();
    });
  }

  static desktopExpander(): FilterlistExpander | undefined {
    return FilterlistExpander.factory.byId("heureka_filterlistExpanderDesktop");
  }

  private init() {
    this.elem.addEventListener("oc-open", () => {
      /*                                                                                                   */
      FilterlistExpander.factory.forEach((expander) => {
        expander.expanded = true;
      });
      /*                                                                                                                                  */
      if (this.isDesktopExpander()) {
        FilterlistExpander.trackSanFoldout(true);
      }
    });
    this.elem.addEventListener("oc-close", () => {
      FilterlistExpander.factory.forEach((expander) => {
        expander.expanded = false;
      });
      if (this.isDesktopExpander()) {
        FilterlistExpander.trackSanFoldout(false);
      }
    });
  }

  static prepareAll(fragment: DocumentFragment) {
    FilterlistExpander.factory.forEach((expander) => FilterlistExpander.#prepare(expander), fragment);
  }

  static #prepare(expander: FilterlistExpander) {
    const oldExpander = FilterlistExpander.filterlistExpanderId(this.getId(expander));
    if (oldExpander) {
      expander.expanded = oldExpander.expanded;
      if (oldExpander.expanded) {
        expander.elem.setAttribute("expanded", "" + oldExpander.expanded);
      }
    }
  }

  private static getId(expander: FilterlistExpander) {
    return expander.elem.id;
  }

  static template(rootElement?: ParentNode | null) {
    return FilterlistExpander.factory.pick(undefined, rootElement);
  }

  get expanded(): boolean {
    return this.elem.classList.contains(FILTERLIST_EXPANDER_EXPANDED_CLASS);
  }

  set expanded(state: boolean) {
    state ? this.elem.setAttribute("expanded", state.toString()) : this.elem.removeAttribute("expanded");
    this.elem.classList.toggle(FILTERLIST_EXPANDER_EXPANDED_CLASS, state);
  }

  isDesktopExpander(): boolean {
    return this.elem.id === FILTERLIST_DESKTOP_EXPANDER_ID;
  }

  static filterlistExpanderId(id: string, root?: NonElementParentNode) {
    return FilterlistExpander.factory.byId(id, root);
  }

  private static trackSanFoldout(expandedState: boolean) {
    const action = expandedState ? "show_more_filters" : "show_less_filters";
    tracker.submitEvent({ san_Foldout: action });
  }
}
