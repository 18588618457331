import { eventQBus } from "../types/EventQBus";
import { appendAll, clone, element, toArray } from "../util/Utils";
import type { FilterSectionLoadedEvent } from "../multifiltering/FilterTypes";
import { SortingInput } from "./SortingInput";
import { SubmitOnChangeListener } from "../filter/SubmitOnChange";
import { SmartSorting } from "./SmartSorting";
import { SortingSubmitAction } from "./SortingSubmitAction";
import { dereference, updateStatus } from "../tracking/FeatureTracking";
import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import { OverflowBox } from "../overflowBox/OverflowBox";

const SORTING_FORM = "ts_sortingForm";
const SELECTOR_TEMPLATE_SORTING_FORM = `#heureka_desktopSorting--initialization form.${SORTING_FORM}`;
const SELECTOR_SORTING_RELEVANT_VALUES = ".heureka_sorting__relevantValues";

export class SortingForm {
  static readonly factory = HeurekaElementFactory.byElement("form", SORTING_FORM, SortingForm);

  /*               */
  constructor(
    readonly form: HTMLFormElement,
    private readonly relevantValues = element(SELECTOR_SORTING_RELEVANT_VALUES, form)
  ) {}

  /*                  */

  static template(rootElement?: ParentNode | null) {
    return SortingForm.factory.pick(SELECTOR_TEMPLATE_SORTING_FORM, rootElement);
  }

  /*               */

  static register() {
    eventQBus.on("heureka.filters.loaded", SortingForm.initAll);
    eventQBus.on("heureka.filterSection.loadAborted", SortingForm.initAll);
  }

  static initAll(event?: FilterSectionLoadedEvent, rootElement?: ParentNode) {
    SortingForm.factory.forEach((form) => form.init(), rootElement);
  }

  protected init() {
    this.form.addEventListener("submit", SortingForm.interceptSubmit);
    const nestedOverflowBox = OverflowBox.factory.pick(undefined, this.form);
    if (nestedOverflowBox) {
      this.form.addEventListener("change", nestedOverflowBox.resetScrollListener());
    }
    SortingInput.initAll({}, this.form);
    SubmitOnChangeListener.on(this.form, SubmitOnChangeListener.submitOnChange(this.submit.bind(this)));
    this.additionalInputs = SmartSorting.template()?.relevantValues;
    return this;
  }

  /*                       */

  get additionalInputs(): HTMLInputElement[] | undefined {
    return (this.relevantValues && toArray(this.relevantValues.children)) || undefined;
  }

  set additionalInputs(values: HTMLInputElement[] | undefined) {
    appendAll(values, this.relevantValues, true);
  }

  get hasAdditionalInputs(): boolean {
    return !!this.relevantValues?.children.length;
  }

  submit(submitter: HTMLElement = this.form) {
    const { hasAdditionalInputs: couldSmartSort, form } = this;
    if (couldSmartSort) {
      form.dataset.tsFeatureAction = "add";
      form.dataset.tsFeatureFilterMethod = "sorting_change";
      this.additionalInputs?.map(dereference).forEach(SortingForm.setChecked);
    }
    form.dispatchEvent(SortingSubmitAction.sortingSubmitEvent(submitter, couldSmartSort));
  }

  clone() {
    return SortingForm.factory.create(clone(this.form)).init();
  }

  /*                 */

  private static interceptSubmit(event: Event) {
    if (event.defaultPrevented) {
      return;
    }

    SortingForm.factory.declare(event.target)?.submit();

    /*                         */
    event.preventDefault();
  }

  /*       */

  private static setChecked(elem: HTMLInputElement) {
    if (!elem.defaultChecked) {
      updateStatus(elem, "preselected", 2);
    }
    elem.checked = true;
  }
}
