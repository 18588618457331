import { eventQBus } from "../types/EventQBus";
import { forEachElement, mapElements } from "../util/Utils";
import { OverflowBoxItem } from "../overflowBox/OverflowBox";
import tracker from "../tracking/Tracker";

const SORT_PARAM = "sortiertnach";
const SELECTOR_SORTING_INPUT = `[name='${SORT_PARAM}']`;

export abstract class SortingInput {
  /*               */
  /*                                                            */
  protected constructor() {}

  static #create(elem: HTMLInputElement | HTMLSelectElement): SortingInput {
    return elem instanceof HTMLSelectElement ? SortingSelectInput.create(elem) : SortingGenericInput.create(elem);
  }

  /*                  */

  static closest(elem: HTMLElement | EventTarget | null) {
    if (elem instanceof HTMLElement) {
      const input = elem.closest<HTMLInputElement | HTMLSelectElement>(SELECTOR_SORTING_INPUT);
      return input ? SortingInput.#create(input) : undefined;
    }
  }

  static forEach(callback: (input: SortingInput, index: number) => void, rootElement?: ParentNode) {
    forEachElement<HTMLInputElement | HTMLSelectElement>(
      SELECTOR_SORTING_INPUT,
      (elem, index) => callback(SortingInput.#create(elem), index),
      rootElement,
    );
  }

  static all(root?: ParentNode | null) {
    return mapElements(SELECTOR_SORTING_INPUT, SortingInput.#create, root);
  }

  /*               */

  static register() {
    eventQBus.on("ftfind.tilelist.loaded", SortingInput.initAll);
  }

  static initAll(event?: { page?: { sortiertnach?: string } }, rootElement?: ParentNode) {
    const { page } = event || {};
    const { sortiertnach } = page || {};

    SortingInput.forEach((input) => input.init(sortiertnach), rootElement);
  }

  protected init(value?: string) {
    if (value) {
      this.selected = value;
    }
  }

  /*                       */

  abstract set selected(value: string);
}

class SortingGenericInput extends SortingInput {
  private constructor(readonly input: HTMLInputElement) {
    super();
  }

  static create(elem: HTMLInputElement) {
    return new SortingGenericInput(elem);
  }

  /*                       */

  set selected(value: string) {
    switch (this.input.type) {
      case "radio":
      case "checkbox":
        this.input.checked = this.input.value === value;
        OverflowBoxItem.toggleFirstItem(this.input, this.input.checked);
        break;
      case "hidden":
      case "text":
        this.input.value = value;
        break;
    }
  }
}

class SortingSelectInput extends SortingInput {
  private constructor(readonly select: HTMLSelectElement) {
    super();
  }

  static create(elem: HTMLSelectElement) {
    return new SortingSelectInput(elem);
  }

  /*               */

  protected init(value: string) {
    super.init(value);
    this.#registerOnClick();
  }

  #registerOnClick() {
    this.select.addEventListener("click", SortingSelectInput.#clicked, {
      capture: true,
    });
  }

  /*                       */

  set selected(value: string) {
    forEachElement<HTMLOptionElement>(
      "option",
      (option) => (option.defaultSelected = option.value === value),
      this.select,
    );
  }

  /*                 */

  static #clicked() {
    tracker.submitEvent({ san_Foldout: "sorting_open" });
  }
}
