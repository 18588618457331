import { eventQBus } from "../types/EventQBus";
import { Facet } from "./Facet";
import { addFilterButtonTeaser, removeFilterButtonTeaser } from "./FilterButton";

export function registerBlackFridayHandlers() {
  eventQBus.on("heureka.filters.loaded", blackFridayFlagTeaser);
  eventQBus.on("heureka.filterVisibilities.loaded", blackFridayFlagTeaser);
}

function blackFridayFlagTeaser() {
  /*                                                                                     */
  const blackFridayFacet: Facet | undefined = Facet.facetId("black-friday");

  if (blackFridayFacet && !blackFridayFacet.facet.hidden) {
    addFilterButtonTeaser("black-friday");
  } else {
    removeFilterButtonTeaser();
  }
}
