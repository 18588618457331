import { forEachElement } from "../util/Utils";
import { eventQBus } from "../types/EventQBus";
import { updateLabel } from "../tracking/FeatureTracking";
import { OverflowBoxItem } from "./OverflowBox";
import StringHelper from "../util/StringHelper";
import { isDesktop } from "../util/Breakpoint";

function updateTrackingLabels(alwaysVisible: boolean, facetValue: HTMLElement) {
  const isVisible = alwaysVisible || OverflowBoxItem.isVisibleIfCollapsed(facetValue);
  updateLabel(facetValue, "san_FacetValueInitialVisible", StringHelper.toBooleanString(isVisible));
}

function onFilterSectionLoaded() {
  const alwaysVisible = isDesktop();
  forEachElement(
    "#heureka_filterTitles .heureka_filterTitle:not(heureka_filterTitle--onlyTitle) .heureka_overflowBox [data-ts-feature-name='Facet-Value']",
    updateTrackingLabels.bind(undefined, alwaysVisible)
  );
}

export function registerSheetOverflowBoxTrackingHandlers() {
  eventQBus.on("ftfind.filterSheet.opened", onFilterSectionLoaded);
  eventQBus.on("heureka.filters.loaded", onFilterSectionLoaded);
}
