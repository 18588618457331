import type { TrackingLabels } from "./TrackingLabels";
import { eventQBus } from "../types/EventQBus";
import { isActive } from "../toggle/Toggle";
import type { Action, EventMergeId, Feature } from "./Tracking";
import { type DataContainer, tracking } from "@otto-ec/tracking-bct";

class Tracker {
  debug = false;

  enableDebug(): void {
    this.debug = true;
  }

  static convertLabelsToDataContainer(labels: Partial<TrackingLabels>): DataContainer {
    const dataContainer: DataContainer = {};

    Object.keys(labels).forEach((key) => {
      const value = labels[key as keyof TrackingLabels];
      if (value !== undefined) {
        dataContainer[key] = Array.isArray(value) ? value : [value];
      }
    });

    return dataContainer;
  }

  submitEvent(labels: Partial<TrackingLabels>): void {
    if (this.debug) {
      console.log("submitEvent", labels);
    }
    if (isActive("HEUREKA_1553_MIGRATE_TRACKING_DISPATCHING")) {
      tracking.submitEvent(Tracker.convertLabelsToDataContainer(labels), undefined);
    } else {
      eventQBus.emit("tracking.bct.submitEvent", labels);
    }
  }

  submitAction(labels: Partial<TrackingLabels>, action: Action): void {
    if (this.debug) {
      console.log("submitAction", labels, action);
    }
    if (isActive("HEUREKA_1553_MIGRATE_TRACKING_DISPATCHING")) {
      tracking.submitEvent(Tracker.convertLabelsToDataContainer(labels), action);
    } else {
      eventQBus.emit("tracking.bct.submitAction", labels, action);
    }
  }

  submitMoveAction(labels: Partial<TrackingLabels>, action: Action): void {
    if (this.debug) {
      console.log("submitMoveAction", labels, action);
    }
    if (isActive("HEUREKA_1553_MIGRATE_TRACKING_DISPATCHING")) {
      tracking.submitMove(Tracker.convertLabelsToDataContainer(labels), action);
    } else {
      eventQBus.emit("tracking.bct.submitMoveAction", labels, action);
    }
  }

  addToEvent(labels: Partial<TrackingLabels>, eventMergeId: EventMergeId): void {
    if (this.debug) {
      console.log("addToEvent", labels, eventMergeId);
    }
    if (isActive("HEUREKA_1553_MIGRATE_TRACKING_DISPATCHING")) {
      tracking.submitEventMerge(eventMergeId, Tracker.convertLabelsToDataContainer(labels));
    } else {
      eventQBus.emit("tracking.bct.addToEvent", labels, eventMergeId);
    }
  }

  addActionToEvent(action: Action, eventMergeId: EventMergeId): void {
    if (this.debug) {
      console.log("addActionToEvent", action, eventMergeId);
    }
    if (isActive("HEUREKA_1553_MIGRATE_TRACKING_DISPATCHING")) {
      tracking.submitEventMerge(eventMergeId, {}, action);
    } else {
      eventQBus.emit("tracking.bct.addActionToEvent", action, eventMergeId);
    }
  }

  addToPageImpression(labels: Partial<TrackingLabels>): void {
    if (this.debug) {
      console.log("addToPageImpression", labels);
    }
    if (isActive("HEUREKA_1553_MIGRATE_TRACKING_DISPATCHING")) {
      tracking.submitMerge(Tracker.convertLabelsToDataContainer(labels));
    } else {
      eventQBus.emit("tracking.bct.addToPageImpression", labels);
    }
  }

  addFeaturesToPageImpression(features: Feature[]): void {
    if (this.debug) {
      console.log("addFeaturesToPageImpression", features);
    }
    if (isActive("HEUREKA_1553_MIGRATE_TRACKING_DISPATCHING")) {
      tracking.submitMerge({}, features);
    } else {
      eventQBus.emit("tracking.bct.addFeaturesToPageImpression", features);
    }
  }

  trackOnNextPageImpression(labels: Partial<TrackingLabels>): void {
    if (this.debug) {
      console.log("trackOnNextPageImpression", labels);
    }
    if (isActive("HEUREKA_1553_MIGRATE_TRACKING_DISPATCHING")) {
      tracking.trackOnNextPageImpression(Tracker.convertLabelsToDataContainer(labels));
    } else {
      eventQBus.emit("tracking.bct.trackOnNextPageImpression", labels);
    }
  }
}

const tracker = new Tracker();
export default tracker;
