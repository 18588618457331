import "./sass/private.scss";
import { eventLoader } from "@otto-ec/global-resources/event-loader";
import { registerFilterSheetHandlers } from "./filtersheet/FilterSheetHandlers";
import { registerFilterSheetTracking } from "./filtersheet/FilterSheetTracking";
import { registerFilterSheetHistory } from "./filtersheet/FilterSheetHistory";
import { registerQuickFilterButtonHandlers } from "./quickfilter/QuickFilterButton";
import registerSearchableFacetHandlers from "./filter/SearchableFacet";
import registerFilterFormActionsHandlers from "./filter/FilterFormActions";
import { SubmitOnChangeListener } from "./filter/SubmitOnChange";
import { initToggles, isActive, isAnyActive } from "./toggle/Toggle";
import registerExpanderHandlers from "./expander/Expander";

import "./filter/FilterSection";
import { OverflowBox } from "./overflowBox/OverflowBox";
import { getActiveVariation, initExperiments } from "./experiment/Experiments";
import { registerFilterSheetEventListeners } from "./filtersheet/FilterSheetEventListeners";
import { registerFilterReloading } from "./multifiltering/init";
import { registerSheetOverflowBoxTrackingHandlers } from "./overflowBox/SheetInlineFilterOverflowBoxTracking";
import { registerSheetExpanderTrackingHandlers } from "./expander/SheetInlineFilterExpanderTracking";
import { registerFilterFormSelectAllButtons } from "./filter/form/SelectAllButton";
import { registerPopularFacetValuesListeners } from "./filter/PopularFacetValues";
import { registerSorting } from "./sorting/init";
import { registerRefinementBar } from "./refinementBar/init";
import { registerFilterStuff } from "./filter/init";
import { FilterSorting } from "./filter/FilterSorting";
import { FilterSection } from "./filter/FilterSection";
import { Slider } from "./filter/slider/Slider";
import registerFilterTracking from "./filter/FilterTracking";
import OttoSwitchBanner from "./switchBanner/OttoSwitchBanner";
import { FilterSortingOld } from "./filter/FilterSortingOld";
import { FilterlistExpander } from "./filterlistExpander/FilterlistExpander";
import { isProductList } from "./util/Utils";
import { registerBlackFridayHandlers } from "./filter/BlackFriday";

eventLoader.onReady(1, () => {
  initToggles();
  initExperiments();
});

eventLoader.onReady(2, () => {
  registerExpanderHandlers();
  OverflowBox.register();
});

eventLoader.onReady(3, () => {
  Slider.register();
  registerFilterSheetHandlers();
  FilterSection.register();
  registerFilterSheetEventListeners();
  registerFilterSheetHistory();
  registerFilterSheetTracking();
  registerQuickFilterButtonHandlers();
  registerSheetOverflowBoxTrackingHandlers();
  registerSheetExpanderTrackingHandlers();
  registerSearchableFacetHandlers();
  registerFilterFormSelectAllButtons();
  registerFilterFormActionsHandlers();
  if (
    isAnyActive("HEUREKA_1338_CRISPY_FILTER_LISTS_E3213", "HEUREKA_1338_CRISPY_FILTER_LISTS") &&
    getActiveVariation("e3213") === "TestGroup" &&
    !isProductList()
  ) {
    FilterSorting.register();
    FilterlistExpander.register();
  } else {
    FilterSortingOld.register();
  }
  registerFilterStuff();
  registerSorting();
  SubmitOnChangeListener.register();
  registerPopularFacetValuesListeners();
  if (
    isAnyActive("HEUREKA_1363_RETAILER_OTTO_TOGGLE_E3245", "HEUREKA_1363_RETAILER_OTTO_TOGGLE") &&
    getActiveVariation("e3245", "StatusQuo") == "Toggle"
  ) {
    OttoSwitchBanner.register();
  }
  registerRefinementBar();
  registerFilterTracking();
  registerFilterReloading();
  if (isActive("HEUREKA_1515_BLACK_FRIDAY_FILTER")) {
    registerBlackFridayHandlers();
  }
});
