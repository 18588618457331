import { quickFilterButton } from "../filtersheet/Selectors";

const FLAG_TEASER_ATTRIBUTE = "data-flagteaser";

export function addFilterButtonTeaser(value: string) {
  quickFilterButton()?.setAttribute(FLAG_TEASER_ATTRIBUTE, value);
}

export function removeFilterButtonTeaser() {
  quickFilterButton()?.removeAttribute(FLAG_TEASER_ATTRIBUTE);
}
