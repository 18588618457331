declare global {
  interface HTMLElement {
    delayedAction: DelayedAction;
  }
}

const DEFAULT_DELAY = 1000;

type DelayParams = { delayInMillis?: number; actionToPerform?: () => boolean };

export default class DelayedAction {
  private timerHandle?: number = undefined;
  private throttleHandle?: number = undefined;
  private scheduledAction?: () => boolean;

  private constructor(
    private readonly anchor: HTMLElement,
    private readonly actionToPerform: () => boolean,
    private readonly delayInMillis: number,
    private readonly throttleInMillis: number
  ) {}

  public static create(
    anchor: HTMLElement,
    actionToPerform: () => boolean,
    delayInMillis?: number,
    throttleInMillis?: number
  ): DelayedAction {
    if (!anchor.delayedAction) {
      anchor.delayedAction = new DelayedAction(
        anchor,
        actionToPerform,
        delayInMillis || Number(anchor.dataset.delayedActionMillis) || DEFAULT_DELAY,
        throttleInMillis || Number(anchor.dataset.delayedActionMillis) || DEFAULT_DELAY
      );
    }
    return anchor.delayedAction;
  }

  throttle({ throttleInMillis = this.throttleInMillis }) {
    this.cancel();
    if (this.throttleHandle) {
      clearTimeout(this.throttleHandle);
    }
    this.throttleHandle = window.setTimeout(() => (this.throttleHandle = undefined), throttleInMillis);
  }

  delay({ delayInMillis = this.delayInMillis, actionToPerform = this.actionToPerform }: DelayParams = {}) {
    if (this.throttleHandle) {
      return;
    }
    this.cancel();
    this.scheduledAction = this.action.bind(this, actionToPerform);
    this.timerHandle = window.setTimeout(this.scheduledAction, delayInMillis);
  }

  cancel() {
    if (this.timerHandle) {
      window.clearTimeout(this.timerHandle);
      return this.clear();
    }
  }

  /**
 *
 */
  nowOrNever(): boolean {
    const cancelledAction = this.cancel();
    return cancelledAction ? cancelledAction() : false;
  }

  private action(actionToPerform: () => boolean = this.actionToPerform) {
    /*                         */
    this.clear();
    if (document.contains(this.anchor)) {
      /*                                                          */
      return actionToPerform();
    }
    return false;
  }

  private clear() {
    const cancelledAction = this.scheduledAction;
    this.timerHandle = undefined;
    this.scheduledAction = undefined;
    return cancelledAction;
  }
}
